@tailwind base;
@tailwind components;
@tailwind utilities;

.layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(252, 247, 236, 1) 36%
  );
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  max-width: 100vw;
}
.content {
  flex: 1 0;
  width: 100%;
}

.card-custom {
  height: 700px;
}
.card-absolute {
  right: 40px;
  top: 80%;
  width: 50%;
}
.card-absolute {
  right: 40px;
  top: 40%;
  transform: translateY(-40%);
}
.parrafo {
  white-space: pre-wrap;
}

.gradient-blue {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 242, 246, 1) 64%,
    rgba(220, 231, 238, 1) 97%
  );
}
.gradient-brown {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(230, 217, 210, 1) 77%,
    rgba(210, 186, 173, 1) 100%
  );
}

@media (max-width: 1023px) {
  .card-absolute {
    top: 10%;
    transform: translateY(-10%);
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
}
