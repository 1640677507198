.logo-text {
  font-family: "Seaweed Script", cursive;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.kalam {
  font-family: "Kalam", cursive;
}

.head {
  max-height: 1080px;
}
.head-top {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(252, 247, 236, 1) 36%
  );
  z-index: 1000;
}
html {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(252, 247, 236, 1) 36%
  );
}
